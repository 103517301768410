<template>
  <div
    v-if="!isCheckingLink"
    class="auth-wrapper auth-v2"
  >
    <div
      v-if="isValidLink"
      class="auth-inner"
    >
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>

        <h2 class="text--primary">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row
        v-if="isValidLink"
        class="auth-row ma-0"
      >
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
            />

            <!-- tree -->
            <v-img
              height="226"
              width="300"
              class="auth-tree"
              src="@/assets/images/misc/tree-2.png"
            ></v-img>

            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="100%"
                height="710"
                class="auth-3d-group"
                :src="require(`@/assets/images/3d-characters/illustration-register-v2-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary my-2">
                    Reset Password
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="resetPasswordForm"
                    v-model="isValid"
                    @submit.prevent="handleFormSubmit"
                  >
                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Password"
                      :error-messages="errorMessages.password"
                      placeholder="Password"
                      :rules="[validators.required, validators.passwordValidator]"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      hide-details="auto"
                      class="mb-2"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <v-text-field
                      v-model="confirmPassword"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Confirm Password"
                      :error-messages="errorMessages.password"
                      placeholder="Confirm Password"
                      :rules="[validators.required, validators.passwordValidator]"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      hide-details="auto"
                      class="mb-2"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <v-btn
                      block
                      color="primary"
                      type="submit"
                      :disabled="!isValid"
                      :loading="loading"
                      class="mt-6"
                    >
                      Reset Password
                    </v-btn>
                  </v-form>
                </v-card-text>

                <!-- create new account  -->
                <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                  <router-link :to="{name:'auth-login'}">
                    Login
                  </router-link>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <v-row
      v-else
      class="px-5 ma-10"
    >
      <v-col
        cols="12"
        sm="12"
      >
        Invalid or Expired Link
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar.isSnackbarVisible"
      :timeout="snackbar.snackbarTimeOut"
    >
      <span v-html="snackbar.snackbarMessage"></span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar.isSnackbarVisible = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
// eslint-disable-next-line object-curly-newline
import { required, passwordValidator, alphaValidator } from '@core/utils/validation'
import { ref, onMounted } from '@vue/composition-api'
import axios from '@axios'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'

export default {
  setup() {
    // Template Refs
    const resetPasswordForm = ref(null)
    const isValidLink = ref(false)
    const isCheckingLink = ref(true)
    const { route, router } = useRouter()

    const isPasswordVisible = ref(false)
    const password = ref('')
    const confirmPassword = ref('')
    const errorMessages = ref([])
    const isValid = ref(false)
    const loading = ref(false)

    const snackbar = ref({
      isSnackbarVisible: false,
      snackBarColor: 'info',
      snackbarMessage: '',
      snackbarTimeOut: '3000',
    })

    const validate = () => {
      resetPasswordForm.value.validate()
    }

    const showSnackbarMessage = data => {
      snackbar.value.isSnackbarVisible = true
      snackbar.value.snackBarColor = data.type
      snackbar.value.snackbarMessage = data.message
    }

    onMounted(() => {
      isCheckingLink.value = true
      axios
        .get('/userAccess/authentication/password-reset-link/validate', { params: { token: route.value.params.token } })
        .then(() => {
          isValidLink.value = true
        })
        .catch(error => {
          errorMessages.value = error.response.data.error
          isValidLink.value = false
        })
        .finally(() => {
          isCheckingLink.value = false
        })
    })

    const handleFormSubmit = () => {
      if (isValid.value) {
        loading.value = true
        axios
          .post('/userAccess/authentication/reset-password', { token: route.value.params.token, password: confirmPassword.value })
          .then(() => {
            showSnackbarMessage({ type: 'info', message: 'Your password has been reseted' })
            password.value = ''
            confirmPassword.value = ''
            router.push({ name: 'auth-login' })
          })
          .catch(error => {
            showSnackbarMessage({ message: error.response.data, type: 'error' })
          })
          .finally(() => {
            loading.value = false
          })
      } else {
        validate()
      }
    }

    return {
      isPasswordVisible,
      password,
      confirmPassword,
      errorMessages,
      isValidLink,
      isValid,
      isCheckingLink,
      snackbar,
      loading,
      resetPasswordForm,
      showSnackbarMessage,
      handleFormSubmit,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        passwordValidator,
        alphaValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
